import { region, bucketName } from '../common/env';
import * as cos from '../api/cos';
import axios from "axios";
import COS from "cos-js-sdk-v5";
import { v4 as uuidv4 } from 'uuid';

let cosInstance = new COS({
  // 必选参数
  getAuthorization: function (options, callback) {
    cos.getSign(bucketName, region).then(res => {
      console.log(res)
      callback({
        TmpSecretId: res.data.msg.credentials.tmpSecretId,
        TmpSecretKey: res.data.msg.credentials.tmpSecretKey,
        XCosSecurityToken: res.data.msg.credentials.sessionToken,
        StartTime: res.data.msg.startTime,
        ExpiredTime: res.data.msg.expiredTime,
      });
    })
  }
});

export function changeUnit(limit) {
  var size = "";
  if (limit < 0.1 * 1024) {                            //小于0.1KB，则转化成B
    size = limit + "B"
  } else if (limit < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + "KB"
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB"
  } else {                                            //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
  }

  var sizeStr = size + "";                        //转成字符串
  var index = sizeStr.indexOf(".");                    //获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 2)            //获取小数点后两位的值
  if (dou == "00") {                                //判断后两位是否为00，如果是则删除00                
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size;
}

export function upload(file, dirName) {
  let key = dirName + '/' + uuidv4() + file.name.substr(file.name.lastIndexOf('.'));
  return new Promise((resolve, reject) => {
    cosInstance.putObject({
      Bucket: bucketName, /* 必须 */
      Region: region,     /* 存储桶所在地域，必须字段 */
      Key: key,
      StorageClass: 'STANDARD',
      Body: file, // 上传文件对象
    }, function (err, data) {
      if (err) {
        console.log(err || data);
        reject();
      } else {
        let result = {};
        result.name = file.name;
        result.path = key;
        result.fullPath = 'http://' + data.Location;
        resolve(result)
      }
    });
  });
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {Promise} 
 */
export function getBlob(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };

    xhr.send();
  });
}

/**
 * 保存
 * @param  {Blob} blob     
 * @param  {String} filename 想要保存的文件名称
 */
export function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    const body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  }
}

/**
 * 下载
 * @param  {String} url 目标文件地址
 * @param  {String} filename 想要保存的文件名称
 */
export function download(url, filename) {
  getBlob(url).then(blob => {
    saveAs(blob, filename);
  });
}